// imports
import styles from '../css/app.scss';
import noScroll from 'no-scroll';
import throttle from 'lodash.throttle';
import svg4everybody from 'svg4everybody';

import Forms from './components/Forms';
import { ProcessAnimation } from './components/process';
import { Illustrations } from './components/illustrations';

//
// App async loading modules
//
const main = async () => {
  // // Conditionally load process component
  // const ProcessEl = document.getElementById('ProcessAnimation');
  // if (ProcessEl) {
  //   const { ProcessAnimation } = await import(/* webpackChunkName: "ProcessAnimation" */ './components/process');
  //   new ProcessAnimation(ProcessEl);
  // }
  // // Conditionally load animated illustrations component
  // const Illustration = document.querySelector('.AnimatedIllustration');
  // if (Illustration) {
  //   const { Illustrations } = await import(/* webpackChunkName: "AnimatedIllustrations" */ './components/illustrations');
  //   Illustrations.init();
  // }
};

//
// Application
//
const CareerMentor = {

  // Options / globals
  bodyElement: null,
  burgerMenubtn: null,
  cases: null,

  init () {
    // Execute async function
    main().then( () => {
      console.log('Modules loaded');
    });

    // Body
    this.bodyElement = document.getElementsByTagName('body')[0];

    // Burger menu noscroll toggle
    this.burgerMenubtn = document.getElementById('NavTrigger');
    if (this.burgerMenubtn) {
      this.burgerMenubtn.addEventListener('click', this.toggleNoScroll);
    }

    // Cases
    this.cases = Array.from( document.querySelectorAll('.Case') );
    if(this.cases) {
      this.cases.forEach(acase => {
        acase.addEventListener('click', (e) => {
          this.playCase(acase);
        });
      });
    }

    const ProcessEl = document.getElementById('ProcessAnimation');
    if (ProcessEl) {
      new ProcessAnimation(ProcessEl);
    }

    const Illustration = document.querySelector('.AnimatedIllustration');
    if (Illustration) {
      Illustrations.init();
    }

    // On scroll
    window.addEventListener('scroll', throttle(this.onScroll, 1000 / 60));
    window.addEventListener('scroll', throttle(this.scrollDelayed, 200));

    // Forms
    Forms.init();

    // Polyfills
    svg4everybody();
  },

  initOnLoad () {
    console.log('Made with 💚💚💚 by https://hhb.dk');
    // console.log('test');
  },

  onResize () {
    //console.log('Resize');
  },

  onScroll () {
    // console.log("Scroll");
  },

  scrollDelayed () {
    // scroll delayed
  },

  toggleNoScroll () {
    CareerMentor.bodyElement.classList.toggle('nav-open');
    noScroll.toggle();
  },

  playCase(acase) {
    let overlayImage = acase.querySelector('.Case__image');
    let video = acase.querySelector('video');
    overlayImage.classList.add('Case__image--hide');
    video.play();
  }

};

document.addEventListener('DOMContentLoaded', () => CareerMentor.init());
window.addEventListener('load', () => CareerMentor.initOnLoad());
