import { SignupAnimation } from './signup.js';
import { PreferencesAnimation } from './preferences.js';
import { SearchAnimation } from './search';
import { TrophyAnimation } from './trophy';

export const Animations = {

  init(illustrations) {
    // Attach animations to illustration elements
    illustrations.forEach(illustration => {
      // Build the right animation depending on class
      let startAnimationMethod = false;
      // Case on svg class
      let svg = illustration.querySelector('svg');
      let name = svg.getAttribute('data-name');
      switch (name) {
        case "signup":
          SignupAnimation.build(svg);
          startAnimationMethod = SignupAnimation.start;
          break;
        case "preferences":
          PreferencesAnimation.build(svg);
          startAnimationMethod = PreferencesAnimation.start;
          break;
        case "search":
          SearchAnimation.build(svg);
          startAnimationMethod = SearchAnimation.start;
          break;
        case "trophy":
          TrophyAnimation.build(svg);
          startAnimationMethod = TrophyAnimation.start;
          break;
      }

      // Attach particular animation instances play method to this elements play function
      if (startAnimationMethod) {
        illustration.playAnimation = function() {
          console.log("Play SVG animation for: ", name);
          startAnimationMethod();
        }
      } else {
        illustration.playAnimation = function() {
          console.log("SVG has no animation: ", name);
        }
      }
      
    });
  },

};