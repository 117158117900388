// Dependencies
import { TweenMax, TimelineMax } from "gsap/TweenMax";

export const SignupAnimation = {
  // Elements
  svg: null,
  timeline: null,
  fadeout: false,

  build(svg, fadeout) {
    this.svg = svg;

    // Cancel if root element not found
    if ( !this.svg ) {
      return;
    }
    if (fadeout) {
      this.fadeout = fadeout;
    }

    // Get all nedeed elements
    this.signupSquare = this.svg.querySelector('.Signup__square');
    this.signupCircleOne = this.svg.querySelector('.Signup__circleOne');
    this.signupCircleTwo = this.svg.querySelector('.Signup__circleTwo');
    this.signupCircleThree = this.svg.querySelector('.Signup__circleThree');
    this.signupCircleFour = this.svg.querySelector('.Signup__circleFour');
    this.signupPhone = this.svg.querySelector('.Signup__phone');
    this.signupScreen = this.svg.querySelector('.Signup__screen');
    this.signupAvatar = this.svg.querySelector('.Signup__avatar');
    this.signupButton = this.svg.querySelector('.Signup__button');
    this.signupLineOne = this.svg.querySelector('.Signup__lineOne');
    this.signupLineTwo = this.svg.querySelector('.Signup__lineTwo');
    this.signupLineThree = this.svg.querySelector('.Signup__lineThree');
    this.signupPerson = this.svg.querySelector('.Signup__person');

    // Setup initial state
    this.setupIllustration();

    // Create tweenmax timeline
    this.createTimeline(this.fadeout);

  },

  setupIllustration() {
    // Setup illustration elements
    TweenMax.set(this.signupSquare, {
      opacity: 0,
      y: 100,
      transformOrigin: "50% 50%",
      rotation: '-25',
    });
    TweenMax.set([this.signupCircleOne, this.signupCircleTwo, this.signupCircleThree, this.signupCircleFour], {
      opacity: 0,
      y: 50,
    });
    TweenMax.set([this.signupPhone, this.signupScreen, this.signupAvatar, this.signupButton], {
      opacity: 0,
      y: 100,
    });
    TweenMax.set([this.signupLineOne, this.signupLineTwo, this.signupLineThree], {
      opacity: 0,
      scale: 0.5,
      y: 30,
      transformOrigin: "50% 50%",
    });
    TweenMax.set(this.signupPerson, {
      opacity: 0,
      x: 100,
    });

  },

  createTimeline(fadeout) {
    // Animation timeline
    this.timeline = new TimelineMax({ paused: true });

    this.timeline.add( () => {
      this.svg.style.opacity = 1;
    }).to(this.signupCircleFour, 0.5, {
      opacity: 1,
      y: 0,
      ease: Power2.easeOut
    }).to(this.signupCircleOne, 0.5, {
      opacity: 1,
      y: 0,
      ease: Power2.easeOut
    },'-=0.4').to(this.signupCircleTwo, 0.5, {
      opacity: 1,
      y: 0,
      ease: Power2.easeOut
    },'-=0.4').to(this.signupCircleThree, 0.5, {
      opacity: 1,
      y: 0,
      ease: Power2.easeOut
    },'-=0.4').to(this.signupSquare, 0.8, {
      opacity: 0.6,
      rotation: '0',
      y: 0,
      ease: Power2.easeOut
    },'-=0.4').to(this.signupPhone, 0.5, {
      opacity: 1,
      y: 0,
      ease: Power2.easeOut
    },'-=0.4').to(this.signupScreen, 0.5, {
      opacity: 1,
      y: 0,
      ease: Back.easeOut.config(4),
    },'-=0.4').to(this.signupAvatar, 0.5, {
      opacity: 1,
      y: 0,
      ease: Back.easeOut.config(4),
    },'-=0.4').to(this.signupButton, 0.5, {
      opacity: 1,
      y: 0,
      ease: Back.easeOut.config(4),
    },'-=0.5').to(this.signupLineOne, 0.3, {
      opacity: 1,
      y: 0,
      scale: 1,
      ease: Power2.easeOut
    }).to(this.signupLineTwo, 0.3, {
      opacity: 1,
      y: 0,
      scale: 1,
      ease: Power2.easeOut
    },'-=0.2').to(this.signupLineThree, 0.3, {
      opacity: 1,
      y: 0,
      scale: 1,
      ease: Power2.easeOut
    },'-=0.2').to(this.signupPerson, 1, {
      opacity: 1,
      x: 0,
      ease: Power2.easeOut
    },'-=1.5');

    if(fadeout) {
      this.timeline.add( TweenMax.to(this.svg, 0.5, {
        opacity: 0,
        ease: Power2.easeOut,
        delay: '1.7'
      }) );
    }

  },

  start() {
    SignupAnimation.timeline.play();
  },
  
};