import IntersectionObserverPoly from 'intersection-observer';
import { Animations } from './animations/index.js';

export const Illustrations = {
  // Elements
  illustrations: [],
  // Vars
  observerOptions: {
    root: null,
    rootMargin: '0px',
    threshold: 0.6
  },

  init () {
    // Get root elements
    this.illustrations = Array.from( document.querySelectorAll('.AnimatedIllustration') );

    // Cancel if illustrations not found
    if ( !this.illustrations ) {
      return;
    }

    Animations.init(this.illustrations);

    // Start observing all animated illustrations
    this.observeIllustrations();

  },

  observeIllustrations() {

    if (typeof IntersectionObserver !== "undefined") {
      // Observer API exists
      let observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if ( entry.isIntersecting ) {
            this.playIllustration(entry.target);
            observer.unobserve(entry.target);
          }
        });
      }, this.observerOptions);

      this.illustrations.forEach(image => {
        observer.observe(image);
      });

    } else {
      // Observer API not found, use poly
      let observer = new IntersectionObserverPoly((entries) => {
        entries.forEach(entry => {
          if ( entry.isIntersecting ) {
            this.playIllustration(entry.target);
            observer.unobserve(entry.target);
          }
        });
      }, this.observerOptions);

      this.illustrations.forEach(image => {
        observer.observe(image);
      });
      
    }
  },

  playIllustration(illustration) {
    illustration.playAnimation();
  }
  
};