import SVGChevronRight from './icons/chevron-right';
import SVGLoadingCircle from './icons/loading-circle';
import SVGCross from './icons/cross';
import SVGCheckmark from './icons/checkmark';
import axios from 'axios';

const Forms = {
  forms: [],

  init () {
    // Get root elements
    this.forms = Array.from( document.querySelectorAll('.Form') );

    // Cancel if not any root form elements found
    if ( !this.forms ) {
      return;
    }

    // Init inputs fields to listen for content
    this.initInputs();

    this.forms.forEach( form => {
      // console.log("init form: ", form);
      form.addEventListener('submit', e => {
        e.preventDefault();
        Forms.sendFormData(form);
      });
    });

  },

  initInputs () {
    const inputs = Array.from(document.querySelectorAll('.Form__input'));
    inputs.forEach(function(input) {
      // console.log("init form input: ", input);
      // If input already has content add class
      if (input.value) {
        input.classList.add('has-content');
      }
      input.addEventListener('blur', function() {
        if (input.value) {
          // If input has received content add class
          input.classList.add('has-content');
        } else {
          // If input no longer has content remove class
          input.classList.remove('has-content');
        }
      });
    });
  },

  sendFormData ( form ) {
    let submitBtn = form.querySelector('.Form__submit');
    let indicator = form.querySelector('.Form__indicator');
    let successMessage = form.querySelector('.Form__successMessage');
    let errorMessage = form.querySelector('.Form__errorMessage');
    let formID = form.id;
    let submitValue = submitBtn.value;
    let submitWait = form.querySelector('input[name="wait"]').value;
    console.log("Submitting form ID: ", formID);

    // Disable submit while processing request
    submitBtn.disabled = true;

    // Hide previous success/error messages (if any)
    successMessage.classList.remove('Form__successMessage--showMessage');
    errorMessage.classList.remove('Form__errorMessage--showMessage');
    successMessage.innerHTML = '';
    errorMessage.innerHTML = '';

    // Indicate loading with text and loading icon
    indicator.innerHTML = SVGLoadingCircle;
    submitBtn.value = submitWait;
    submitBtn.style.cursor = 'wait';

    // Delay value in milliseconds to allow send animation to run once
    const delayShort = 300;
    const delayLong = 500;

    // Data to send
    let formData = new FormData(form);

    // Rest route
    let restRoute = 'careermentor/v1/send-enquiry';

    // Axios post to backend
    axios.post(THEME.restURL + restRoute, formData, {headers: {
      'X-WP-Nonce': THEME.nonce,
    }})
    .then( (response) => {
      // Form was succesfully send
      setTimeout( () => {

        // Show success message
        successMessage.classList.add('Form__successMessage--showMessage');
        successMessage.innerHTML = '<p>' + response.data + '</p>';

        // Indicate success in icon
        indicator.style.opacity = '0';
        setTimeout( () => {
          indicator.style.opacity = '1';
          indicator.innerHTML = SVGCheckmark;
        }, delayShort);

        // Hide values
        let formInputs = [...form.querySelectorAll('input')];
        formInputs.forEach(function(input) {
          input.value = '';
          input.classList.remove('has-content');
        });
        let textArea = form.querySelector('textarea');
        if (textArea) {
          textArea.value = '';
          textArea.classList.remove('has-content');
        }

      }, delayLong);
    })
    .catch( (error) => {
      // Form submit encountered an error
      console.log(error.response);

      setTimeout( () => {
        // Show error message
        errorMessage.classList.add('Form__errorMessage--showMessage');

        if (error.response.data) {
          // If the server responded with an error show it
          errorMessage.innerHTML = '<p>' + error.response.data.message + '</p>';
        } else {
          // If the server for some reason did not respond anything show fallback error
          errorMessage.innerHTML = '<p>An uknown error has occured</p>';
        }

        // Indicate error in icon 
        setTimeout( () => {
          indicator.innerHTML = SVGCross;
        }, delayShort);

      }, delayLong);
    })
    .then( () => {
      setTimeout( () => {
        // Fade out icon
        indicator.style.opacity = '0';

        // Re-enable submit button
        submitBtn.disabled = false;
        submitBtn.value = submitValue;
        submitBtn.style.cursor = 'auto';

        // Fade icon in again (after icon change set by success/error)
        setTimeout( () => {
          indicator.style.opacity = '1';
        }, delayShort);

      }, delayLong);

      // Show default icon again after 2s delay
      setTimeout( () => {
        indicator.style.opacity = '0';
      }, 1700);
      setTimeout( () => {
        indicator.style.opacity = '1';
        indicator.innerHTML = SVGChevronRight;
      }, 2000);

    });
    
  },

};

export default Forms;