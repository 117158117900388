// Dependencies
import { TweenMax, TimelineMax } from "gsap/TweenMax";

export const PreferencesAnimation = {
  // Elements
  svg: null,
  timeline: null,
  fadeout: false,

  build(svg, fadeout) {
    this.svg = svg;

    // Cancel if form not found
    if ( !svg ) {
      return;
    }
    if (fadeout) {
      this.fadeout = fadeout;
    }

    // Get all nedeed elements
    this.preferencesPerson = this.svg.querySelector('.Preferences__person');
    this.preferencesBg = this.svg.querySelector('.Preferences__bg');
    this.preferencesWindow = this.svg.querySelector('.Preferences__window');
    this.preferencesButtons = this.svg.querySelector('.Preferences__buttons');
    this.preferencesLineOne = this.svg.querySelector('.Preferences__lineOne');
    this.preferencesLineTwo = this.svg.querySelector('.Preferences__lineTwo');
    this.preferencesLineThree = this.svg.querySelector('.Preferences__lineThree');
    this.preferencesLineFour = this.svg.querySelector('.Preferences__lineFour');
    this.preferencesLineFive = this.svg.querySelector('.Preferences__lineFive');
    this.preferencesLineSix = this.svg.querySelector('.Preferences__lineSix');
    this.preferencesLineSeven = this.svg.querySelector('.Preferences__lineSeven');

    // Setup initial state
    this.setupIllustration();

    // Create tweenmax timeline
    this.createTimeline(this.fadeout);

  },

  setupIllustration() {
    // Setup illustration elements
    TweenMax.set(this.svg, {
      opacity: 0,
    });
    TweenMax.set([this.preferencesPerson, this.preferencesBg, this.preferencesWindow], {
      opacity: 0,
      y: 50,
    });
    TweenMax.set([this.preferencesLineOne, this.preferencesLineTwo, this.preferencesLineThree, this.preferencesLineFour, this.preferencesLineFive, this.preferencesLineSix, this.preferencesLineSeven], {
      scale: 0,
      transformOrigin: "50% 0%",
    });
    TweenMax.set(this.preferencesButtons, {
      opacity: 0,
      x: -30,
    });

  },

  createTimeline(fadeout) {
    // Animation timeline
    this.timeline = new TimelineMax({ paused: true });

    this.timeline.add( () => {
      this.svg.style.opacity = 1;
    }).to(this.preferencesBg, 0.5, {
      opacity: 0.6,
      y: 0,
      ease: Power2.easeOut
    }).to(this.preferencesWindow, 0.5, {
      opacity: 1,
      y: 0,
      ease: Power2.easeOut
    },'-=0.3').to(this.preferencesLineOne, 0.2, {
      opacity: 0.3,
      scale: 1,
      ease: Power2.easeOut
    }).to(this.preferencesLineTwo, 0.2, {
      opacity: 0.3,
      scale: 1,
      ease: Power2.easeOut
    },'-=0.1').to(this.preferencesLineThree, 0.2, {
      opacity: 0.2,
      scale: 1,
      ease: Power2.easeOut
    },'-=0.1').to(this.preferencesLineFour, 0.2, {
      opacity: 0.2,
      scale: 1,
      ease: Power2.easeOut
    },'-=0.1').to(this.preferencesLineFive, 0.2, {
      opacity: 0.3,
      scale: 1,
      ease: Power2.easeOut
    },'-=0.1').to(this.preferencesLineSix, 0.2, {
      opacity: 0.2,
      scale: 1,
      ease: Power2.easeOut
    },'-=0.1').to(this.preferencesLineSeven, 0.2, {
      opacity: 0.3,
      scale: 1,
      ease: Power2.easeOut
    },'-=0.1').to(this.preferencesButtons, 0.3, {
      opacity: 1,
      x: 0,
      ease: Power2.easeOut
    },'-=0.1').to(this.preferencesPerson, 1, {
      opacity: 1,
      y: 0,
      ease: Power2.easeOut
    },'-=0.2');

    if(fadeout) {
      this.timeline.add( TweenMax.to(this.svg, 0.5, {
        opacity: 0,
        ease: Power2.easeOut,
        delay: '1'
      }) );
    }

  },

  start() {
    PreferencesAnimation.timeline.play();
  },
  
};