// Dependencies
import { TweenMax, TimelineMax } from "gsap/TweenMax";

export const SearchAnimation = {
  // Elements
  svg: null,
  timeline: null,
  fadeout: false,

  build(svg, fadeout) {
    this.svg = svg;

    // Cancel if form not found
    if ( !svg ) {
      return;
    }
    if (fadeout) {
      this.fadeout = fadeout;
    }

    // Get all nedeed elements
    this.searchBg = this.svg.querySelector('.Search__bg');
    this.searchWindow = this.svg.querySelector('.Search__window');
    this.searchPlant = this.svg.querySelector('.Search__plant');
    this.searchPersonOne = this.svg.querySelector('.Search__personOne');
    this.searchPersonTwo = this.svg.querySelector('.Search__personTwo');
    this.searchPersonThree = this.svg.querySelector('.Search__personThree');
    this.searchPersonOneAvatar = this.svg.querySelector('.Search__personOneAvatar');
    this.searchPerson = this.svg.querySelector('.Search__person');
    this.searchMagnifier = this.svg.querySelector('.Search__magnifier');

    // Setup initial state
    this.setupIllustration();

    // Create tweenmax timeline
    this.createTimeline(this.fadeout);

  },

  setupIllustration() {
    // Setup illustration elements
    TweenMax.set(this.svg, {
      opacity: 0,
    });
    TweenMax.set([this.searchPerson, this.searchBg, this.searchPersonTwo, this.searchPersonThree], {
      opacity: 0,
      y: 50,
    });
    TweenMax.set([this.searchWindow], {
      scale: 0.5,
      y: 50,
      opacity: 0,
      transformOrigin: "50% 50%",
    });
    TweenMax.set([this.searchMagnifier], {
      opacity: 0,
      y: 30,
    });
    TweenMax.set([this.searchPlant], {
      opacity: 0,
      x: 50,
    });
    TweenMax.set([this.searchPersonOne], {
      opacity: 0,
      y: 50,
    });
    TweenMax.set([this.searchPersonOneAvatar], {
      transformOrigin: "50% 50%",
    });

  },

  createTimeline(fadeout) {
    // Animation timeline
    this.timeline = new TimelineMax({ paused: true });

    this.timeline.add( () => {
      this.svg.style.opacity = 1;
    }).to(this.searchBg, 0.5, {
      opacity: 0.5,
      y: 0,
      ease: Power2.easeOut
    }).to(this.searchWindow, 0.5, {
      opacity: 1,
      y: 0,
      scale: 1,
      ease: Back.easeOut.config(2),
    },'-=0.3').to(this.searchPersonOne, 0.3, {
      opacity: 0.5,
      y: 0,
      ease: Power2.easeOut
    }).to(this.searchPersonTwo, 0.3, {
      opacity: 1,
      y: 0,
      ease: Power2.easeOut
    },'-=0.2').to(this.searchPersonThree, 0.3, {
      opacity: 1,
      y: 0,
      ease: Power2.easeOut
    },'-=0.2').to(this.searchPlant, 0.3, {
      opacity: 1,
      x: 0,
      ease: Power2.easeOut
    },'-=0.2').to(this.searchPerson, 0.5, {
      opacity: 1,
      y: 0,
      ease: Power2.easeOut
    },'-=0.2').to(this.searchMagnifier, 0.5, {
      opacity: 1,
      y: 0,
      ease: Power2.easeOut
    },'-=0.2')
    .to(this.searchPersonOneAvatar, 0.3, {
      scale: 1.15,
      ease: Back.easeOut.config(4),
    }).to(this.searchPersonOne, 0.1, {
      opacity: 1,
      ease: Power2.easeOut
    },'-=0.3');

    if(fadeout) {
      this.timeline.add( TweenMax.to(this.svg, 0.5, {
        opacity: 0,
        ease: Power2.easeOut,
        delay: '1'
      }) );
    }

  },

  start() {
    SearchAnimation.timeline.play();
  },
  
};