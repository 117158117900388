// Dependencies
import { TweenMax, TimelineMax } from "gsap/TweenMax";
import { SignupAnimation } from '../illustrations/animations/signup.js';
import { PreferencesAnimation } from '../illustrations/animations/preferences.js';
import { SearchAnimation } from '../illustrations/animations/search.js';
import { TrophyAnimation } from '../illustrations/animations/trophy.js';

export class ProcessAnimation {

  constructor(root) {
    // Get root
    this.root = root;
    // Observer option
    this.observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    };
    // Get illustrations
    this.signup = document.getElementById('Signup');
    this.preferences = document.getElementById('Preferences');
    this.search = document.getElementById('Search');
    this.trophy = document.getElementById('Trophy');
    // Get circle
    this.circle = document.getElementById('Circle');
    this.circleFull = this.circle.querySelector('.circle-full');
    this.circleFullLength = this.circleFull.getTotalLength();
    this.circleDotted = this.circle.querySelector('.circle-dotted');
    this.circleOne = this.circle.querySelector('.circle-one');
    this.circleTwo = this.circle.querySelector('.circle-two');
    this.circleThree = this.circle.querySelector('.circle-three');
    this.circleFour = this.circle.querySelector('.circle-four');
    // Get list
    this.processListOne = document.getElementById('ProcessListOne');
    this.processListTwo = document.getElementById('ProcessListTwo');
    this.processListThree = document.getElementById('ProcessListThree');
    this.processListFour = document.getElementById('ProcessListFour');
  

    this.initialize();
  }

  initialize() {
    SignupAnimation.build(this.signup, true);
    PreferencesAnimation.build(this.preferences, true);
    SearchAnimation.build(this.search, true);
    TrophyAnimation.build(this.trophy, true);
    // Setup circle
    this.setupCircle();
    // Create tweenmax timeline
    this.createTimeline();
    // Observe intersection
    this.observe();
  }

  setupCircle() {
    // Setup circle
    TweenMax.set(this.circleFull, {
      strokeDasharray: this.circleFullLength,
      strokeDashoffset: this.circleFullLength,
    });
  }

  createTimeline() {
    // Init timelines
    this.circleTimeline = new TimelineMax({ paused: true, repeat: -1 });
    this.timelineOne = SignupAnimation.timeline;
    this.timelineTwo = PreferencesAnimation.timeline;
    this.timelineThree = SearchAnimation.timeline;
    this.timelineFour = TrophyAnimation.timeline;
    console.log();

    // Setup circle timeline
    this.circleTimeline.to(this.circleFull, 12, {
      strokeDashoffset: 0,
      ease: Power0.easeNone
    }).add( () => {
      this.timelineOne.restart(0);
      this.circleOne.style.fill = '#fe9236';
      this.processListOne.classList.add('Process__list--current');
    }, '-=12').add( () => {
      this.circleTwo.style.fill = '#fe9236';
      this.timelineTwo.restart(0);
      this.processListOne.classList.remove('Process__list--current');
      this.processListTwo.classList.add('Process__list--current');
    }, '-=8').add( () => {
      this.circleThree.style.fill = '#fe9236';
      this.timelineThree.restart(0);
      this.processListTwo.classList.remove('Process__list--current');
      this.processListThree.classList.add('Process__list--current');
    }, '-=4').add( () => {
      this.circleFour.style.fill = '#fe9236';
      this.processListThree.classList.remove('Process__list--current');
      this.processListFour.classList.add('Process__list--current');
      this.timelineFour.restart(0);
    }, '-=0.15').to(this.circleFull, 1, {
      strokeDashoffset: this.circleFullLength,
      ease: Power0.easeNone
    }, '+=5').add( () => {
      this.circleOne.style.fill = '#abaaa9';
      this.circleTwo.style.fill = '#abaaa9';
      this.circleThree.style.fill = '#abaaa9';
      this.circleFour.style.fill = '#abaaa9';
      this.processListFour.classList.remove('Process__list--current');
    }, '-=1');
    
  }

  playTimeline() {
    // console.log("PLAY!");
    this.circleTimeline.play();
  }

  observe() {
    let observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if ( entry.isIntersecting ) {
          this.playTimeline();
          observer.unobserve(entry.target);
        }
      });
    }, this.observerOptions);

    observer.observe(this.root);
  }

}
