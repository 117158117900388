// Dependencies
import { TweenMax, TimelineMax } from "gsap/TweenMax";

export const TrophyAnimation = {
  // Elements
  svg: null,
  timeline: null,
  fadeout: false,

  build(svg, fadeout) {
    this.svg = svg;

    // Cancel if form not found
    if ( !svg ) {
      return;
    }
    if (fadeout) {
      this.fadeout = fadeout;
    }

    // Get all nedeed elements
    this.trophyBg = this.svg.querySelector('.Trophy__bg');
    this.trophyMan = this.svg.querySelector('.Trophy__man');
    this.trophyWoman = this.svg.querySelector('.Trophy__woman');
    this.trophyTrophy = this.svg.querySelector('.Trophy__trophy');

    // Setup initial state
    this.setupIllustration();

    // Create tweenmax timeline
    this.createTimeline(this.fadeout);

  },

  setupIllustration() {
    // Setup illustration elements
    TweenMax.set(this.svg, {
      opacity: 0,
    });
    TweenMax.set(this.trophyBg, {
      opacity: 0,
      y: 100,
      scale: 0,
      transformOrigin: "50% 50%",
    });
    TweenMax.set(this.trophyMan, {
      opacity: 0,
      x: 50,
      y: 50,
    });
    TweenMax.set(this.trophyWoman, {
      opacity: 0,
      x: -50,
    });
    TweenMax.set(this.trophyTrophy, {
      opacity: 0,
      y: 50,
      scale: 0.5,
      transformOrigin: "50% 50%",
    });

  },

  createTimeline(fadeout) {
    // Animation timeline
    this.timeline = new TimelineMax({ paused: true });

    this.timeline.add( () => {
      this.svg.style.opacity = 1;
    }).to(this.trophyBg, 0.5, {
      opacity: 0.1,
      y: 0,
      scale: 1,
      ease: Power2.easeOut
    }).to(this.trophyMan, 0.5, {
      opacity: 1,
      y: 0,
      x: 0,
      ease: Power2.easeOut
    },'-=0.1').to(this.trophyWoman, 0.5, {
      opacity: 1,
      x: 0,
      ease: Power2.easeOut
    },'-=0.3').to(this.trophyTrophy, 0.6, {
      opacity: 1,
      y: 0,
      scale: 1,
      ease: Back.easeOut.config(2),
    },'-=0.3');

    if(fadeout) {
      this.timeline.add( TweenMax.to(this.svg, 0.5, {
        opacity: 0,
        ease: Power2.easeOut,
        delay: '4'
      }) );
    }

  },

  start() {
    TrophyAnimation.timeline.play();
  },
  
};